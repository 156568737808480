/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DictionaryValue } from './dictionary-value';


export interface ProductGeneralInfo {
    bioBasedContentPart?: number;
    recommendation?: string;
    replaces?: string;
    type: Array<string>;
    category?: Array<string>;
    format?: Array<DictionaryValue>;
    industryFocuses?: Array<DictionaryValue>;
    supplyChainFlow?: SupplyChainFlow;
    customerFocus?: Array<string>;
    algaeType?: Array<string>;
}

export interface SupplyChainFlow {
    "1": string[],
    "2": string[],
    "3": string[],
    "4": string[],
    "5": string[],
    "6": string[]
}

