import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from './core/components/layout/layout.component';
import {AuthComponent} from './core/components/auth-modal-window/auth.component';
import {PlatformUserAuthGuard} from './core/guards/platform-user-auth.guard';
import {AdminGuard} from './core/guards/admin.guard';
import {NotFoundPageCircularRedirectGuard} from "./core/guards/not-found-page-circular-redirect.guard";
import {SignupsListComponent} from './signups/components/signups-list/signups-list.component';
import {DictionariesManagementComponent} from './dictionaries/components/dictionaries-management/dictionaries-management.component';
import {UsersListComponent} from './users/components/users-list/users-list.component';
import {UserProfileComponent} from './users/components/user-profile/user-profile.component';
import {UserEditComponent} from './users/components/user-edit/user-edit.component';
import {ManifestoComponent} from './core/components/manifesto/manifesto.component';
import {FaqComponent} from './core/components/faq/faq.component';
import {AttributesSettingsComponent} from './attributes-settings/components/attributes-settings/attributes-settings.component';
import {ContactUsComponent} from "./core/components/contact-us/contact-us.component";
import {NotFoundPageComponent} from "./core/components/not-found-page/not-found-page.component";
import {SignUpBuyerComponent} from "./core/components/sign-up-buyer/sign-up-buyer.component";
import {SignUpSupplierComponent} from "./core/components/sign-up-supplier/sign-up-supplier.component";
import {CompaniesListComponent} from "./companies/components/companies-list/companies-list.component";
import {ProfileComponent} from "./companies/components/profile/profile.component";
import {ProductsListComponent} from "./products/components/products-list/products-list.component";
import {CampaignsComponent} from "./campaigns/components/campaigns/campaigns.component";
import {AboutUsComponent} from "./core/components/about-us/about-us.component";
import {NewHomeComponent} from "./home/new-home/new-home.component";
import {FeaturedProductsShortListComponent} from "./products/components/featured-products/products-short-list/featured-products-short-list.component";
import {FeaturedProductsComponent} from "./products/components/featured-products/featured-products.component";
import {CreatorLedComponent} from "./core/components/creator-led/creator-led.component";
import {FaqPageComponent} from "./core/components/creator-led/faq-page/faq-page.component";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    data: { breadcrumb: {skip: true} },
    children: [
      {
        path: '',
        component: NewHomeComponent,
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        canActivate: [PlatformUserAuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'creators',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CreatorLedComponent,
            data: {breadcrumb: 'Creator-led Products'}
          },
          {
            path: 'faq',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: FaqPageComponent,
                data: {breadcrumb: 'Creator-led FAQs'}
              }
            ]
          }
        ]
      },
      {
        path: 'manifesto',
        component: ManifestoComponent,
        data: { breadcrumb: 'Manifesto' }
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { breadcrumb: 'FAQ' }
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        data: { breadcrumb: 'Contact Us' }
      },
      {
        path: 'about-us',
        component: AboutUsComponent,
        data: { breadcrumb: 'About Us' }
      },
      {
        path: 'sign-in',
        component: AuthComponent,
        data: { breadcrumb: 'Sign In' }
      },
      {
        path: 'not-found',
        component: NotFoundPageComponent,
        canDeactivate: [NotFoundPageCircularRedirectGuard],
        data: { breadcrumb: 'Page Not Found' }
      },
      {
        path: 'authentication/login',
        component: NewHomeComponent,
        data: { breadcrumb: 'Home' }
      },
      {
        path: 'authentication/signup',
        component: NewHomeComponent,
        data: { breadcrumb: 'Home' }
      },
      {
        path: 'password/reset',
        component: NewHomeComponent,
        data: { breadcrumb: 'Home' }
      },
      {
        path: 'sign-up-supplier',
        component: SignUpSupplierComponent,
        data: { breadcrumb: 'Sign Up' }
      },
      {
        path: 'sign-up-buyer',
        component: SignUpBuyerComponent,
        data: { breadcrumb: 'Sign Up' }
      },
      {
        path: 'companies',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: CompaniesListComponent,
            canActivate: [AdminGuard],
            data: { breadcrumb: 'Companies' },
          },
          {
            path: ':company-alias',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: ProfileComponent,
                data: { breadcrumb: 'Company' }
              },
              {
                path: 'products',
                loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
                data: { breadcrumb: 'Products' },
              },
              {
                path: 'campaigns',
                loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule),
                data: { breadcrumb: 'Campaigns' }
              }
            ]
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ProductsListComponent,
            canActivate: [PlatformUserAuthGuard],
            data: { breadcrumb: 'Products' },
          },
          {
            path: 'featured',
            children: [
              {
                path: 'lists',
                component: FeaturedProductsComponent,
                canActivate: [AdminGuard],
                data: { breadcrumb: 'Featured'}
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: "/products/featured/Hotels%20and%20Hospitality",
                data: { breadcrumb: 'Featured'}
              },
              {
                path: ':name',
                component: FeaturedProductsShortListComponent,
                data: { breadcrumb: 'Featured'}
              },
            ]
          }
        ]
      },
      {
        path: 'campaigns',
        component: CampaignsComponent,
        data: { breadcrumb: 'Campaigns' }
      },
      {
        path: 'administration',
        canActivate: [AdminGuard],
        data: { breadcrumb: {skip: true} },
        children: [
          {
            path: 'users',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: UsersListComponent,
                canActivate: [AdminGuard],
                data: { breadcrumb: 'Users' },
              },
              {
                path: ':id',
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    component: UserProfileComponent,
                    canActivate: [AdminGuard],
                    data: { breadcrumb: 'User' }
                  },
                  {
                    path: 'edit',
                    component: UserEditComponent,
                    canActivate: [AdminGuard],
                    data: { breadcrumb: 'User Editing' }
                  },
                ]
              }
            ]
          },
          {
            path: 'sign-ups',
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: SignupsListComponent,
                canActivate: [AdminGuard],
                data: { breadcrumb: 'Sign Ups' }
              }
            ]
          },
          {
            path: 'dictionaries',
            component: DictionariesManagementComponent,
            canActivate: [AdminGuard],
            data: { breadcrumb: 'Dictionaries' }
          },
          {
            path: 'attributes',
            component: AttributesSettingsComponent,
            canActivate: [AdminGuard],
            data: { breadcrumb: 'Attributes' }
          },
        ]
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: UserProfileComponent,
            canActivate: [PlatformUserAuthGuard],
            data: {
              currentProfile: true,
              breadcrumb: 'Profile'
            }
          },
          {
            path: 'edit',
            component: UserEditComponent,
            canActivate: [PlatformUserAuthGuard],
            data: {
              currentProfile: true,
              breadcrumb: 'Profile Editing'
            }
          },
          {
            path: 'orders',
            loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
            data: { breadcrumb: 'Orders' }
          },
        ]
      },
      {
        path: '**',
        redirectTo: ''
      },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule,
    RouterModule.forRoot(routes, {initialNavigation: 'enabled', scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
