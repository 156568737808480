import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DictionaryValue, ProductResponse, ProductsApiService, SupplyChainFlow} from '../../../../api/cs';
import {DictionariesService} from '../../../../shared/services/dictionaries.service';
import {LoaderService} from '../../../../shared/services/loader.service';
import {AttributesService} from '../../../../shared/services/attributes.service';
import {AdminViewService} from "../../../../shared/services/admin-view.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";

@Component({
  selector: 'cs-product-info-form',
  templateUrl: './product-info-form.component.html',
  styleUrls: ['./product-info-form.component.scss']
})
export class ProductInfoFormComponent implements OnInit {

  form: FormGroup;
  typeForm: FormGroup;
  categoryForm: FormGroup;
  algaeTypeForm: FormGroup;
  customerFocusForm: FormGroup;
  supplyChainForm: FormGroup;

  private product: ProductResponse | undefined;
  private requiredFields: string[] = ['bioContent'];
  isAdminView: boolean;

  types: string[];
  categories: string[];
  algaeTypes: string[];
  customerFocuses: string[];
  supplyChainFlow: Map<string, string[]>;
  industryFocuses: DictionaryValue[];
  bioSources: DictionaryValue[];
  materialTypes: DictionaryValue[];
  properties: DictionaryValue[];
  productProcessings: DictionaryValue[];
  formats: DictionaryValue[];

  showType: boolean;
  showCategory: boolean;
  showProcessing: boolean;
  showIndustryFocuses: boolean;
  showFormat: boolean;
  showRecommendation: boolean;
  showProductReplacement: boolean;
  showBioContent: boolean;
  showBioSources: boolean;
  showMaterialTypes: boolean;
  showProperties: boolean;

  @Output()
  updatedProduct = new EventEmitter<ProductResponse>();

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProductInfoFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {product: ProductResponse},
              private attributesService: AttributesService,
              private productsApiService: ProductsApiService,
              private dictionariesService: DictionariesService,
              public adminViewService: AdminViewService) {
  }

  ngOnInit(): void {
    this.loader.show();
    this.product = this.data.product;
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.isAdminView = this.adminViewService.isAdminView;
    this.adminViewService.adminView.subscribe(isAdminView => this.isAdminView = isAdminView);
    this.resolveVisibility();
    this.dictionariesService.loaded$.subscribe(() => {
      this.setDictionaryValues();
      this.setPropertyValues();
      this.initForm();
      this.loader.hide();
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  private initForm(): void {
    const industryFocusesForm = this.fb.group({});
    this.industryFocuses.forEach((industryFocus) => {
      if (industryFocus.key != null) {
        industryFocusesForm.addControl(industryFocus.key,
          new FormControl(this.isKeyChosen(industryFocus.key, this.product?.generalInfo.industryFocuses)));
      }
    });

    const formatForm = this.fb.group({});
    this.formats.forEach((format) => {
      if (format.key != null) {
        formatForm.addControl(format.key,
          new FormControl(this.isKeyChosen(format.key, this.product?.generalInfo.format)));
      }
    });

    this.typeForm = this.fb.group({});
    this.categoryForm = this.fb.group({});
    this.algaeTypeForm = this.fb.group({});
    this.customerFocusForm = this.fb.group({});
    this.supplyChainForm = this.fb.group({});

    this.form = this.fb.group({
      type: this.typeForm,
      category: this.categoryForm,
      algaeType: this.algaeTypeForm,
      supplyChainFlow: this.supplyChainForm,
      customerFocus: this.customerFocusForm,
      format: formatForm,
      recommendation: new FormControl(this.product?.generalInfo?.recommendation),
      productReplacement: new FormControl(this.product?.generalInfo?.replaces),
      bioContent: new FormControl(this.product?.generalInfo?.bioBasedContentPart,
        [Validators.pattern('^[0-9]*[.,]?[0-9]+$'), Validators.max(100)]),
      industryFocuses: industryFocusesForm
    })
  }

  private setPropertyValues(): void {
    this.productsApiService.getProductGeneralValues().subscribe(response => {
      this.types = response.type;
      response.type.forEach((value) => {
        if (value != null) {
          this.typeForm.addControl(value,
              new FormControl(this.product?.generalInfo.type.includes(value)));
        }
      });
      this.categories = response.category || [];
      response.category?.forEach((value) => {
        if (value != null) {
          this.categoryForm.addControl(value,
              new FormControl(this.product?.generalInfo.category?.includes(value)));
        }
      });
      this.algaeTypes = response.algaeType || [];
      response.algaeType?.forEach((value) => {
        if (value != null) {
          this.algaeTypeForm.addControl(value,
              new FormControl(this.product?.generalInfo.algaeType?.includes(value)));
        }
      });
      this.customerFocuses = response.customerFocus || [];
      response.customerFocus?.forEach((value) => {
        if (value != null) {
          this.customerFocusForm.addControl(value,
              new FormControl(this.product?.generalInfo.customerFocus?.includes(value)));
        }
      });
      this.supplyChainFlow = response.supplyChainFlow || new Map;
      for (let [key, values] of Object.entries(response.supplyChainFlow || [])) {
        this.supplyChainForm.addControl(key, this.fb.group({}));
        values.forEach((value: string) => {
          if (value != null && !!this.product?.generalInfo.supplyChainFlow) {
            for (let [actualKey, actualValues] of Object.entries(this.product?.generalInfo.supplyChainFlow)) {
              if (actualKey == key) {
                (<FormGroup>this.supplyChainForm.get(key))?.addControl(value,
                    new FormControl(actualValues.includes(value)));
              }
            }
          }
        });
      }
    })
  }

  isKeyChosen(searchedKey: any, array: any[] | undefined): boolean {
    return !!array ? array.map(value => value.key).includes(searchedKey) : false;
  }

  submit(): void {
    if (this.form.invalid) {
      for (let field of this.requiredFields) {
        this.form.get(field)!.markAsTouched();
      }
      const inputs = Array.from(document
        .getElementById("general-info-modal-card")!
        .querySelectorAll('input'));
      for (let input of inputs) {
        if (input.getAttribute("class")!.includes("invalid")) {
          input.scrollIntoView({block: 'center'});
          return;
        }
      }
    } else {
      this.loader.show();
      const {bioContent, recommendation, productReplacement, type, category,
        algaeType, customerFocus, supplyChainFlow} = this.form.value;
      this.productsApiService.updateProductGeneral({
         id: this.product?.id || '',
        productGeneralInfo: {
          bioBasedContentPart: bioContent,
          recommendation: recommendation,
          replaces: productReplacement,
          type: this.convertFormControlToJsonArray(type),
          category: this.convertFormControlToJsonArray(category),
          algaeType: this.convertFormControlToJsonArray(algaeType),
          customerFocus: this.convertFormControlToJsonArray(customerFocus),
          supplyChainFlow: this.convertFormControlToSupplyChainFlowObject(supplyChainFlow),
          format: this.getCheckedDictionaryValues('product-format', 'format'),
          industryFocuses: this.getCheckedDictionaryValues('common-industry-focus', 'industryFocuses')
        }
      }).subscribe((product: ProductResponse) => {
          this.updatedProduct.emit(product);
          this.loader.hide();
          this.dialogRef.close();
        });
    }
  }

  private convertFormControlToJsonArray(group: any): Array<string> {
    return Array.from(Object.keys(group).filter((key: string) => group[key]).map(key => key.toString()));
  }

  private convertFormControlToSupplyChainFlowObject(group: any): SupplyChainFlow {
    let map: Map<string, string[]> = new Map<string, string[]>();
    Object.entries(group).forEach((value) => {
      map.set(value[0], this.convertFormControlToJsonArray(value[1]));
    })
    return new class implements SupplyChainFlow {
      "1": string[] = map.get("1") || [];
      "2": string[] = map.get("2") || [];
      "3": string[] = map.get("3") || [];
      "4": string[] = map.get("4") || [];
      "5": string[] = map.get("5") || [];
      "6": string[] = map.get("6") || [];
    }
  }

  getSelectedDictionaryValue(dictionaryName: string, selectedValue: string): DictionaryValue | undefined {
    if (!dictionaryName || !selectedValue) {
      return undefined;
    }
    return this.dictionariesService.getDictionaryByName(dictionaryName).find(value => value.key == selectedValue);
  }

  getCheckedDictionaryValues(dictionaryName: string, controlName: string): DictionaryValue[] {
    const control = this.form.get(controlName) as FormGroup;
    if (!dictionaryName || !control) {
      return [];
    }
    let values = Object.keys(control.controls).filter((key) => control.value[key]);
    return this.dictionariesService.getDictionaryByName(dictionaryName).filter(value => values.includes(value.key || ''));
  }

  private setDictionaryValues(): void {
    this.dictionariesService.loaded$.subscribe(() => {
      this.setIndustryFocuses();
      this.setFormats();
    });
  }

  private setIndustryFocuses(): void {
    this.industryFocuses = this.dictionariesService.getDictionaryByName(
      'common-industry-focus',
      true,
      this.product?.generalInfo?.industryFocuses);
  }

  private setFormats(): void {
    this.formats = this.dictionariesService.getDictionaryByName(
      'product-format',
      false,
      this.product?.generalInfo?.format);
  }

  private resolveVisibility(): void {
    this.setTypeVisibility();
    this.setCategoryVisibility();
    this.setIndustryFocusesVisibility();
    this.setFormatVisibility();
    this.setRecommendationVisibility();
    this.setProductReplacementVisibility();
    this.setBioContentVisibility();
  }

  private setTypeVisibility(): void {
    this.showType = this.attributesService.isShownProductAttribute('type');
  }

  private setCategoryVisibility(): void {
    this.showCategory = this.attributesService.isShownProductAttribute('category');
  }

  private setIndustryFocusesVisibility(): void {
    this.showIndustryFocuses = this.attributesService.isShownProductAttribute('industryFocuses');
  }

  private setFormatVisibility(): void {
    this.showFormat = this.attributesService.isShownProductAttribute('format');
  }

  private setRecommendationVisibility(): void {
    this.showRecommendation = this.attributesService.isShownProductAttribute('recommendation');
  }

  private setProductReplacementVisibility(): void {
    this.showProductReplacement = this.attributesService.isShownProductAttribute('replaces');
  }

  private setBioContentVisibility(): void {
    this.showBioContent = this.attributesService.isShownProductAttribute('bioBasedContentPart');
  }
}
